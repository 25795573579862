body {
    font-family: "Proxima Nova", Helvetica, Arial, sans-serif;
    font-size: 14px;
}

.servicePageBanner {
    border-top: 8px solid #ba1e2b;
    background-color: #32393e;
    color: #fff;
    min-height: 55px;
    margin-bottom: 15px;
}

.headerImage {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

h1 {
    font-weight: bold;
    color: #ba1e2b;
    margin: 0 0 20px;
}

h2 {
    font-weight: bold;
    font-size: 24px;
    color: #ba1e2b;
    margin: 0 0 20px;
}

.margin-bottom {
    margin-bottom: 24px;
}
