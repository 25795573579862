.lang {
    min-width: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.dropdown {
    position: absolute;
    z-index: 99;
    top: 100%;
}

.lang .dropdown {
    left: 0;
    display: none;
    margin: 0;
    padding: 0;
    list-style: none;
}

.lang:hover .dropdown {
    width: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.lang:hover .dropdown li {
    margin: 0;
    display: block;
    padding: 5px 0;
}

.lang li button, .lang > button {
    padding: 3px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    border:  1px solid #ccc;
    cursor: pointer;
}

.lang button, .lang input[type="submit"], .lang input[type="reset"] {
    /*background: none;*/
    color: inherit;
    /*border: none;*/
    /*padding: 0;*/
    font: inherit;
    cursor: pointer;
    outline: inherit;
}

.nl {
    background-image: url("../../public/assets/icons/nl.svg");
}

.dk {
    background-image: url("../../public/assets/icons/dk.svg");
}

.en {
    background-image: url("../../public/assets/icons/en.svg");
}

.de {
    background-image: url("../../public/assets/icons/de.svg");
}

.fr {
    background-image: url("../../public/assets/icons/fr.svg");
}

